
<template>
  <vx-card>
    <span>Hubspot Webhook Settings</span>
    <vs-button
      class="mt-5"
      align="right"
      @click="$router.push({ name: 'HubspotWebhookSettingsCreate' })"
      icon-pack="feather"
      icon="icon-edit"
      >Add hubspot supplier/courier field</vs-button
    >
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="users"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <template slot="thead">
          <vs-th sort-key="supplierCourier">Supplier/Courier</vs-th>
          <vs-th sort-key="hubspotCode">Hubspot Field Code</vs-th>
          <vs-th sort-key="createdAt">Created At</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="getSupplierName(data[indextr].supplierCourier)">{{
              getSupplierName(data[indextr].supplierCourier)
            }}</vs-td>
            <vs-td :data="data[indextr].hubspotFieldCode">{{
              data[indextr].hubspotFieldCode
            }}</vs-td>
            <vs-td :data="data[indextr].createdAt">{{
              moment(data[indextr].createdAt).format("DD/MM/YYYY")
            }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vx-tooltip text="View/Edit Details">
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  @click="editDetailHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-edit"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Delete">
                <vs-button
                  style="float: left"
                  type="border"
                  size="small"
                  @click="deleteHubspotHandler(data[indextr]._id)"
                  icon-pack="feather"
                  icon="icon-delete"
                  class="m-1"
                ></vs-button>
              </vx-tooltip>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="m-2">
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="9"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    createRoute: {
      type: String,
      default: "HubspotCreate",
    },
  },
  data() {
    return {
      noDataText: "Loading...",
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "asc",
        page: 1,
        limit: 25,
      },
      users: [],
      serverResponded: false,
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      awaitingSearch: null,
      suppliers: [],
    };
  },
  methods: {
    ...mapActions("hubspot", [
      "fetchHubspotSettings",
      "createHubspotSetting",
      "updateHubspotSetting",
      "deleteHubspotSetting",
    ]),
    ...mapActions("storeProducts", ["fetchSuppliers"]),
    moment(date) {
      return moment(date);
    },
    getHubspotSettings() {
      let self = this;
      this.fetchSuppliers().then((res) => {
        self.suppliers = res.data.data;
      });
      this.fetchHubspotSettings(self.dataTableParams).then((res) => {
        self.users = res.data.data;
        self.totalDocs = res.data.data.length;
        self.serverResponded = true;
        self.noDataText = "No Data Found";
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getHubspotSettings();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getHubspotSettings();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getHubspotSettings();
    },

    editDetailHandler(id) {
      this.$router.push({
        name: "HubspotWebhookSettingsCreate",
        params: { id: id },
      });
    },
    createHubspotHandler() {
      this.$router.push({ name: this.createRoute });
    },
    getSupplierName: function (id) {
      let self = this;
      const filter = self.suppliers.filter((item) => item._id === id);
      if (filter.length) return filter[0].name;
      else return "Supplier not found";
    },
    deleteHubspotHandler(id) {
      let self = this;
      const deleteId = id;
      this.deleteHubspotSetting(deleteId)
        .then((res) => {
          self.$vs.notify({
            title: "Setting Deleted",
            text: "Setting deleted Successfully.",
            color: "success",
          });
          this.getHubspotSettings();
        })
        .catch((err) => {
          self.$vs.notify({
            title: "Failed",
            text: "Failed to delete setting. Try again!",
            color: "danger",
          });
          if (err.status === 422) {
            self.mapServerErrors(self, err, "basic");
          }
        });
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getHubspotSettings();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getHubspotSettings();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },

  created() {
    this.getHubspotSettings();
  },
};
</script>
